import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PageComponentName from './pageComponentName';
import { AppConfigContext, AppConfig } from '../util/appConfig';
import Campaign from '../models/Campaign';
import storeUTMCampaign from '../util/storeUTMCampaign';
import getCampaignParameter from '../util/getCampaignParameter';

async function getCampaignFromSalesforce(campaignId: string, appConfig: AppConfig) {
  const response = await axios.get(`${appConfig.apiUrl}/campaigns/${campaignId}`);

  return response.data as Campaign;
}

function resolveUrl(url: string, defaultUrl?: string) {
  if (!url) {
    return defaultUrl || '/';
  }

  const hasScheme = url.startsWith('http://') || url.startsWith('https://');
  if (!hasScheme) {
    return `https://${url}`;
  }

  return url;
}

const PartnerRedirectPage: React.FC = () => {
  const location = useLocation();
  const campaignId = getCampaignParameter(location);
  const appConfig = useContext(AppConfigContext);

  return <PartnerRedirect campaignId={campaignId} appConfig={appConfig} />;
};

type PartnerRedirectProps = {
  campaignId: string;
  appConfig: AppConfig;
};

const PartnerRedirect: React.FC<PartnerRedirectProps> = ({ campaignId, appConfig }) => {
  const [campaign, setCampaign] = useState<Campaign>();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of searchParams) {
      if (key === 'partner_camp_id') {
        localStorage.setItem(key, JSON.stringify(value));
        const now = new Date();
        const currentUTCTime = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
          now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        localStorage.setItem('partner_camp_expiry', (currentUTCTime + (2 * 24 * 60 * 60 * 1000)).toString());
      }
    }
  }, []);

  async function getCampaign() {
    const defaultPartnerUrl = 'https://www.paypal.com/us/webapps/mpp/paypal-business-loan';
    let result;
    try {
      result = await getCampaignFromSalesforce(campaignId, appConfig);
    } catch (error) {
      window.location.href = defaultPartnerUrl;

      return;
    }

    setCampaign(result);

    storeUTMCampaign(campaignId);

    if (!result.url) {
      window.location.href = defaultPartnerUrl;
      return;
    }

    window.location.href = resolveUrl(result.url, defaultPartnerUrl);
  }

  useEffect(() => {
    if (!campaign) {
      getCampaign();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

PartnerRedirectPage.displayName = PageComponentName.PartnerRedirect;

export default PartnerRedirectPage;
